export default function getLanguage(language) {
  var lang;

  switch (language) {
    case "ar":
      lang = "Arabic";
      break;

    case "af":
      lang = "Afrikaans";
      break;

    case "sq":
      lang = "Albanian";
      break;

    case "am":
      lang = "Amharic";
      break;

    case "hy":
      lang = "Armenian";
      break;

    case "as":
      lang = "Assamese";
      break;

    case "az":
      lang = "Azerbaijani";
      break;

    case "eu":
      lang = "Basque";
      break;

    case "be":
      lang = "Belarusian";
      break;

    case "bn":
      lang = "Bengali";
      break;

    case "bs":
      lang = "Bosnian";
      break;

    case "bg":
      lang = "Bulgarian";
      break;

    case "my":
      lang = "Burmese";
      break;

    case "ca":
      lang = "Catalan";
      break;

    case "km":
      lang = "Central Khmer";
      break;

    case "zh":
      lang = "Chinese";
      break;

    case "zh_chs":
      lang = "Chinese Simplified";
      break;

    case "zh_cht":
      lang = "Chinese Traditional";
      break;

    case "co":
      lang = "Corsican";
      break;

    case "hr":
      lang = "Croatian";
      break;

    case "cs":
      lang = "Czech";
      break;

    case "da":
      lang = "Danish";
      break;

    case "prs":
      lang = "Dari";
      break;

    case "dv":
      lang = "Divehi";
      break;

    case "nl":
      lang = "Dutch";
      break;

    case "en":
      lang = "English";
      break;

    case "eo":
      lang = "Esperanto";
      break;

    case "et":
      lang = "Esperanto";
      break;

    case "fj":
      lang = "Fijian";
      break;

    case "fi":
      lang = "Finnish";
      break;

    case "fr":
      lang = "French";
      break;

    case "gl":
      lang = "Galician";
      break;

    case "ka":
      lang = "Georgian";
      break;

    case "de":
      lang = "German";
      break;

    case "el":
      lang = "Greek";
      break;

    case "gu":
      lang = "Gujarati";
      break;

    case "ht":
      lang = "Haitian";
      break;

    case "ha":
      lang = "Hausa";
      break;

    case "he":
      lang = "Hebrew";
      break;

    case "hi":
      lang = "Hindi";
      break;

    case "mww":
      lang = "Hmong Daw";
      break;

    case "hu":
      lang = "Hungarian";
      break;

    case "is":
      lang = "Icelandic";
      break;

    case "ig":
      lang = "Igbo";
      break;

    case "id":
      lang = "Indonesian";
      break;

    case "iu":
      lang = "Inuktitut";
      break;

    case "ga":
      lang = "Irish";
      break;

    case "it":
      lang = "Italian";
      break;

    case "ja":
      lang = "Japanese";
      break;

    case "jv":
      lang = "Javanese";
      break;

    case "kn":
      lang = "Kannada";
      break;

    case "kk":
      lang = "Kazakh";
      break;

    case "rw":
      lang = "Kinyarwanda";
      break;

    case "ky":
      lang = "Kirghiz";
      break;

    case "ko":
      lang = "Korean";
      break;

    case "ku":
      lang = "Kurdish";
      break;

    case "lo":
      lang = "Lao";
      break;

    case "la":
      lang = "Latin";
      break;

    case "lv":
      lang = "Latvian";
      break;

    case "lt":
      lang = "Lithuanian";
      break;

    case "lb":
      lang = "Luxembourgish";
      break;

    case "mk":
      lang = "Macedonian";
      break;

    case "mg":
      lang = "Malagasy";
      break;

    case "ms":
      lang = "Malay";
      break;

    case "ml":
      lang = "Malayalam";
      break;

    case "mt":
      lang = "Maltese";
      break;

    case "mi":
      lang = "Maori";
      break;

    case "mr":
      lang = "Marathi";
      break;

    case "mn":
      lang = "Mongolian";
      break;

    case "ne":
      lang = "Nepali";
      break;

    case "no":
      lang = "Norwegian";
      break;

    case "nn":
      lang = "Norwegian Nynorsk";
      break;

    case "or":
      lang = "Oriya";
      break;

    case "ps":
      lang = "Pasht";
      break;

    case "fa":
      lang = "Persian";
      break;

    case "pl":
      lang = "Polish";
      break;

    case "pt":
      lang = "Portuguese";
      break;

    case "pa":
      lang = "Punjabi";
      break;

    case "otq":
      lang = "Queretaro Otomi";
      break;

    case "ro":
      lang = "Romanian";
      break;

    case "ru":
      lang = "Russian";
      break;

    case "sm":
      lang = "Samoan";
      break;

    case "sr":
      lang = "Serbian";
      break;

    case "sn":
      lang = "Shona";
      break;

    case "sd":
      lang = "Sindhi";
      break;

    case "si":
      lang = "Sinhala";
      break;

    case "sk":
      lang = "Slovak";
      break;

    case "sl":
      lang = "Slovenian";
      break;

    case "so":
      lang = "Somali";
      break;

    case "es":
      lang = "Spanish";
      break;

    case "su":
      lang = "Sundanese";
      break;

    case "sw":
      lang = "Swahili";
      break;

    case "sv":
      lang = "Swedish";
      break;

    case "tl":
      lang = "Tagalog";
      break;

    case "ty":
      lang = "Tahitian";
      break;

    case "tg":
      lang = "Tajik";
      break;

    case "ta":
      lang = "Tamil";
      break;

    case "tt":
      lang = "Tatar";
      break;

    case "te":
      lang = "Telugu";
      break;

    case "th":
      lang = "Thai";
      break;

    case "bo":
      lang = "Tibetan";
      break;

    case "ti":
      lang = "Tigrinya";
      break;

    case "to":
      lang = "Tongan";
      break;

    case "tr":
      lang = "Turkish";
      break;

    case "tk":
      lang = "Turkmen";
      break;

    case "uk":
      lang = "Ukrainian";
      break;

    case "ur":
      lang = "Urdu";
      break;

    case "uz":
      lang = "Uzbek";
      break;

    case "vi":
      lang = "Vietnamese";
      break;

    case "cy":
      lang = "Welsh";
      break;

    case "xh":
      lang = "Xhosa";
      break;

    case "yi":
      lang = "Yiddish";
      break;

    case "yo":
      lang = "Yoruba";
      break;

    case "yua":
      lang = "Yucatec Maya";
      break;

    case "zu":
      lang = "Zulu";
      break;

    case "":
      lang = "NA";
      break;
    case null:
      lang = "NA";
      break;
    default:
      lang = language;
      break;
  }
  return lang;
}

import React from 'react';

import "./Suggestions.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Suggestions(props) {
   
    const suggestionClickHandler = (e) => {
        props.suggestionClickHandler(e.currentTarget.id);
    }

    const borders = {
        boxShadow: "0 2px 3px #ccc",
        boxSizing: "border-box",
    }

    let suggestions = props.suggestions.map((s, index) => {
        return (<div className="suggestion-item" key={index} id={s.queryPlusText} onMouseDown={suggestionClickHandler}>{s.queryPlusText}</div>);
      });

    return (
        <div className="suggestions-box" style={borders}>
            {suggestions}
        </div>
    );
};

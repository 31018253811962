import React from "react";
import { withRouter } from "react-router-dom";
import AppHeader from "../../components/AppHeader/AppHeader";
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      msg : "Redirecting to home page in 5 seconds."
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    setTimeout(() => {
      if(this.props.match.path.includes('/search')) {
        this.props.history.push('/'); 
      } else {
        window.location.reload();
      }
    }, 5000);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="ErrorBoundary">
          <AppHeader nextPage={'searchResultPage'}/>
          <div className="msg">
            <h1>Something went wrong.</h1>
            <p>{this.state.msg}</p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
import React from 'react';
import ReactDOM from 'react-dom'
import App from './App/App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.Fragment>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.Fragment>,
  document.getElementById('root')
);
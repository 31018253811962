import React, { useState, useEffect, useRef } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Highlighter from "react-highlight-words";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import saveAs from "file-saver";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import ErrorBoundary from '../../components/Errorlog/ErrorBoundary';
import { getAppInsights } from '../../TelemetryService';
import UserStore from '../../UserStore';
import Tooltip from '@material-ui/core/Tooltip';
import SearchTranscript from './SearchTranscript/SearchTranscript';
import { observer } from "mobx-react-lite";
import WebViewer from '@pdftron/webviewer';
import "./Details.scss";
import { Col, Row } from "react-bootstrap";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

function Details(props) {
  // eslint-disable-next-line
  const [initalSearchTag, setInitalSearchTag] = useState(props.q.trim());
  const classes = useStyles();
  const [documented, setDocument] = useState({});
  const [selectedTab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { instance, accounts } = useMsal();
  const [sasLink, setSasLink] = useState('');
  const [docs, setDocs] = useState([]);
  const [noRenderer, setNoRenderer] = useState(false);
  const [XMLData, setXMLData] = useState('');
  const [updateRender, setUpdateRender] = useState([]);
  const [CADloader, setCADloader] = useState(true);
  let [accountsValue] = accounts;
  const API_endpoint = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    setDocument(props.detailsDoc);
    setIsLoading(false);
    setTimeout(() => {
      if(document.getElementById('no-renderer')) {
        setNoRenderer(true);
      }
    }, 2000);
    XMLparse(); 
    // eslint-disable-next-line
  }, [noRenderer, sasLink, XMLData, updateRender]);

  useEffect(() => {
    if((initalSearchTag.startsWith("\"") && initalSearchTag.endsWith("\""))) {
      updateRender.push(initalSearchTag.substring(1, initalSearchTag.length-1));
    } else {
      setUpdateRender(initalSearchTag.replace(/["']/g, '').split(" "));
    }
    // eslint-disable-next-line
  }, []);

  let path = documented.metadata_storage_path;

  async function generateSasToken() {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue
    });

    const accessToken = response.accessToken;
    console.log("access Token:-",accessToken);
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    if(path) {
      let res = await axios.get(
        API_endpoint + `/GenerateKmSasToken?fileStoragePath=${encodeURIComponent(path)}`, config
      );
      setSasLink(res.data);
      setDocs([{ uri: res.data }]);
    }
  }

  useEffect(() => {
    generateSasToken();
    //eslint-disable-next-line
  }, [documented, accountsValue]);

  function downloadFile () {
    let docID = documented.document_id;
    let loggedInUser = UserStore.loggedInUser;
    getAppInsights().trackEvent({name: "Click", 
      properties: {
        SearchServiceName: process.env.REACT_APP_SEARCH_SERVICE_NAME,
        ClickedDocId: docID,
        Action: "Download",
        Userid: loggedInUser
      }
    });

    if (path != null) {
      let link = sasLink;
      let fileName = documented.metadata_storage_name;
      axios({
        url: link, 
        method: "GET",
        responseType: "blob"
      }).then(response => {
        var blob = new Blob([response.data], {
          type: "text/plain;charset=utf-8"
        });
        saveAs(blob, fileName);
      });
    }
  }

  async function XMLparse() {
    var docType = documented.metadata_storage_file_extension_lower;

    if(docType === '.xml' || docType === '.XML') {
      let response = await axios.get(sasLink, {
        "Content-Type": "application/xml; charset=utf-8"
      });
      setXMLData(response.data);
    }
  }

  const renderTranscript = (searchTags) => {
    setUpdateRender(searchTags);
  }

  const viewer = useRef(null);

  const findChunksSearch = ({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords,
    textToHighlight
  }) => {
    const chunks = [];
    const textLow = textToHighlight.toLowerCase();
    const sep = /[\s~`!@#$%^&*()-_+=\{\}\[\]|\\:;"'<,\/>.?]/;
    const singleTextWords = textLow.split(sep);

    // It could be possible that there are multiple spaces between words
    // Hence we store the index (position) of each single word with textToHighlight
    let fromIndex = 0;
    const singleTextWordsWithPos = singleTextWords.map(s => {
      const indexInWord = textLow.indexOf(s, fromIndex);
      fromIndex = indexInWord;
      return {
        word: s,
        index: indexInWord
      };
    });

    // Add chunks for every searchWord
    searchWords.forEach(sw => {
      const swLow = sw.toLowerCase();
      // Do it for every single text word
      singleTextWordsWithPos.forEach(s => {
        if (s.word === swLow) {
          const start = s.index;
          const end = s.index + swLow.length;
          chunks.push({
            start,
            end
          });
        }
      });

      // The complete word including whitespace should also be handled
      if(swLow.split(" ").length > 1) {
      for(let index in textLow) {
        let start = parseInt(index);
        let end = parseInt(index) + swLow.length;
        let sub = textLow.substring(start,end);
        let remain = textLow.substring(start,end+1);
        if(sub === swLow && (/[^a-zA-Z0-9]/.test( remain[remain.length - 1] ))) {
          chunks.push({
            start,
            end
          });
        }
      }
    }
    });


    return chunks;
  };


  // PDFTron useEffect
  useEffect(() => {
    if (WebViewer && viewer && viewer.current) {
      setCADloader(true);


      WebViewer(
        {
          path: '/webviewer/lib',
          // webviewerServerURL: 'https://cad-previewer.azurewebsites.net',
          webviewerServerURL: process.env.REACT_APP_PDFTRON_SERVER_URL,
          initialDoc: sasLink,
          licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
        },
        viewer.current,
      ).then((instance) => {
        
        const { annotationManager, documentViewer, Annotations, DocumentViewer,Search } = instance.Core;
       // const cadOpts = new instance.Core.PDFNet.Convert.CADConvertOptions();
       // cadOpts.setZoomToExtents(false);
        //instance.loadDocument(sasLink,{ cadOptions: cadOpts });
        const searchListener = (searchPattern, options, results) => {
          // add redaction annotation for each search result
          const newAnnotations = results.map(result => {
            const annotation = new Annotations.RedactionAnnotation();
            annotation.PageNumber = result.pageNum;
            annotation.Quads = result.quads.map(quad => quad.getPoints());
            annotation.StrokeColor = new Annotations.Color("#0000ffff");
            return annotation;
          });
      
          annotationManager.addAnnotations(newAnnotations);
          annotationManager.drawAnnotationsFromList(newAnnotations);
        };
        let wholeword = false;
        let regexOperator = "";
      
        documentViewer.addEventListener('documentLoaded', () => {
    
          let searchPattern = props.q;

          // searchPattern can be something like "search*m" with "wildcard" option set to true
          // searchPattern can be something like "search1|search2" with "regex" option set to true
          let searchTag = [];
          if(searchPattern !== '*') {
            // eslint-disable-next-line
            searchPattern = searchPattern.trim();
          if((searchPattern.startsWith("\"") && searchPattern.endsWith("\""))) {
            if(searchPattern.search(/\bAND\b/) > 0 || searchPattern.search(/\bOR\b/) > 0 || searchPattern.search(/\bNOT\b/) > 0){
              if(searchPattern.search(/\bAND\b/) > 0 || searchPattern.search(/\bOR\b/) > 0){
                searchTag = searchPattern.replace(new RegExp(/\bAND\b/), "regexOperator").replace(new RegExp(/\bOR\b/), "regexOperator").replace(/"/g,'regexOperator').toLowerCase().split("regexoperator");
                for(let index in searchTag) {
                  searchTag[index] = searchTag[index].trim();
                }
                searchTag = searchTag.filter(function (obj) { 
                  // obj = obj.trim();
                  return !['',' ',null, undefined].includes(obj)
                });
              }else{
                searchTag = searchPattern.replace(/"/g,'regexOperator').split("NOT")[0].toLowerCase().split("regexoperator");
                for(let index in searchTag) {
                  searchTag[index] = searchTag[index].trim();
                }
                searchTag = searchTag.filter(function (obj) { 
                  obj = obj.trim();
                  return !['',' ',null, undefined].includes(obj) });
              }
            }else {
              const searchWord = searchPattern.substring(1, searchPattern.length-1).trim();
              wholeword = true;
              searchTag.push(searchWord + "");
            }
            
          } else {
            // eslint-disable-next-line
            searchTag = searchPattern.toLowerCase().replace(/[\n\t\s]+/, " ").split(" ");
            searchTag = searchTag.filter(function (obj) { return !['',' ',null, undefined].includes(obj) });
    
          }
        } else {
          searchTag = '';
        }
    

         

          if((searchPattern.startsWith("\"") && searchPattern.endsWith("\""))) {
            searchPattern = searchPattern.slice(1,-1);
          }

          // options default values are false
          let searchOptions = {
            caseSensitive: false,  // match case
            wholeWord: wholeword,      // match whole words only
            wildcard: false,      // allow using '*' as a wildcard value
            regex: true,         // string is treated as a regular expression
            searchUp: false,      // search from the end of the document upwards
            ambientString: true,  // return ambient string as part of the result
          };
      
          instance.UI.addSearchListener(searchListener);
         
          // start search after document loads
          let pattern = "";
          if(searchTag.length > 0) {
            pattern = searchTag[0];
            for(let k in searchTag) {
              if(k > 0)
              pattern = pattern + "|" + searchTag[k];
            }
          }

          instance.UI.searchTextFull(pattern,searchOptions);
          instance.UI.disableElements(['annotationDeleteButton']);
          instance.UI.disableElements(['annotationStyleEditButton']);
          instance.UI.disableElements(['annotationCommentButton']);
          instance.UI.disableElements(["panToolButton"]);
          instance.UI.disableElements(["stickyToolButton"]);
          instance.UI.disableElements(["highlightToolButton"]);
          instance.UI.disableElements(["freeHandToolButton"]);
          instance.UI.disableElements(["freeHandHighlightToolButton"]);
          instance.UI.disableElements(["freeTextToolButton"]);
          instance.UI.overrideSearchExecution((searchTerm, options) => {
            if(searchTerm.charAt(searchTerm.length - 1) !== "|") {
              options.regex = true;
              instance.UI.searchTextFull(searchTerm,options)
              pattern = searchTerm;
              searchOptions = options
            }
        });
          instance.UI.searchTextFull(pattern,searchOptions);
        });

        documentViewer.setSearchHighlightColors({
          searchResult: 'rgba(255, 255, 0, 0.5)',
          activeSearchResult: 'rgba(255, 255, 0, 0.5)'
        });
        setCADloader(false);
        // const { documentViewer, DocumentViewer } = instance.Core;

        const fitWidth = () => {
          documentViewer.setFitMode(documentViewer.FitMode.FitWidth);
        };

        documentViewer.addEventListener(DocumentViewer.Events.DOCUMENT_LOADED, fitWidth);
        documentViewer.addEventListener(DocumentViewer.Events.PAGE_NUMBER_UPDATED, fitWidth);

        /* zoom for each page */
        instance.Core.documentViewer.getDisplayModeManager().setDisplayMode(new instance.Core.DisplayMode(instance.Core.documentViewer, 'Single'));
        instance.UI.disableElements(['ribbons']);
        instance.UI.disableElements(['toolsHeader']);

        instance.UI.setHeaderItems(header => {
          const items = header.getItems();
          const newItems = [items[0], items[1], items[2], items[3], items[4], items[9], items[11], items[12]];
          header.update(newItems);
        });
      }).catch((err) => {
        // console.log(err);
      })
    }
  // eslint-disable-next-line
  }, [viewer, sasLink, selectedTab]);

  // View default is loading with no active tab
  let detailsBody = (<CircularProgress />),
      FileStyle = "nav-link",
      metadataStyle    = "nav-link",
      transcriptStyle = "nav-link";

  if (!isLoading && documented && path) {
    // View preview
    let doc = documented.metadata_storage_file_extension_lower;
    let isCAD = !(doc === '.xlsm' || doc === '.XLSM' || doc === '.xml' || doc === '.XML');
    // let  isCAD = true;
    if (selectedTab === 0) {
      FileStyle += " active";

      detailsBody = (
        <div className="card-body">
          <div className="viewer-container">
           <div className="download-img-bg">
           <Tooltip  title={<label className='tootltip-Font'>Download the document</label>} arrow>
                <img src="/images/Download.svg" alt="downloadFile" className="downloadIcon" onClick={downloadFile}></img>
            </Tooltip>
           </div>
            <div className="download-file">
              {(isCAD && CADloader) && <CircularProgress />}
              {
                isCAD ? <div className="webviewer" ref={viewer} style={CADloader ? {height: '0rem'} : {height: "35rem"}}></div> :
                ( doc === '.xml' || doc === '.XML') ? 
                <span>{XMLData ? XMLData : <CircularProgress />}</span> :
                <DocViewer documents={docs} pluginRenderers={DocViewerRenderers}/>
                
              }
              { (noRenderer && !isCAD) &&
              <div className='errorMEssage'>Please use the download option to view file.</div>}
            </div>
          </div>
        </div>
      );
    }
    let transcriptContent = (doc === '.dwg' || doc === '.DWG' || doc === '.dgn' || doc === '.DGN') ? documented.dwg_dgn_content : documented.translated_content;
    let highlightContent = transcriptContent ? transcriptContent : "";
    
    // view transcript
    if (selectedTab === 1) {
      transcriptStyle += " active";
      detailsBody = (
        <div className="card-body text-left transcript">
          {highlightContent ? 
         <Highlighter highlightClassName="YourHighlightClass" searchWords={updateRender} autoEscape={true} textToHighlight={highlightContent} 
         findChunks={(initalSearchTag.startsWith("\"") && initalSearchTag.endsWith("\"")) ? findChunksSearch : undefined}
         /> :
          <span className="no-data">Transcript is not available for this document.</span>}
        </div>
      )
    }

    function createData(name, data) {
      return { name, data };
    }

    const showKeyphrasesAsString = props.keyphrases.join(', ');

    const rows = [
      createData('File name', documented.metadata_storage_name ? documented.metadata_storage_name : "NA"),
      createData('Extension', documented.metadata_storage_file_extension_lower ? documented.metadata_storage_file_extension_lower : "NA"),
      createData('Created on', documented.metadata_creation_date ? moment(documented.metadata_creation_date).format('DD-MM-YYYY') : 'NA'),
      createData('Language', documented.language ? documented.language : "NA"),
      createData('Source', documented.metadata_storage_path? documented.metadata_storage_path : "NA"),
      createData('Keyphrases', documented.keyphrases.length ? showKeyphrasesAsString : "Keyphrases are not available for this document.")
    ];
    
    const table = (
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
              <StyledTableCell align="left">{row.data}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );

    // View meta data
    if (selectedTab === 2) {
      metadataStyle += " active";
      detailsBody = (
        <div className="card-body text-left metadata">
          {table}
        </div>
      );
    }
  }

  return (
    <ErrorBoundary>
    <Row>
      <div className="card-header">
          <ul className="nav nav-tabs">
              <li className="nav-item" style={{paddingRight:"4.4vw"}}><button className={FileStyle} onClick={() => setTab(0)}>File</button></li>
              <li className="nav-item"  style={{paddingRight:"4.4vw"}}><button className={transcriptStyle} onClick={() => setTab(1)}>Transcript</button></li>
              <li className="nav-item"><button className={metadataStyle} onClick={() => setTab(2)}>Metadata</button></li>
          </ul>
        </div>
      </Row>
    <main className="main main--details fluid">
      <Row className='no-gutters'>
        <Col lg={9} sm={12} md={12}>
        <div className="card text-center result-container">
        {detailsBody}
        </div>
        </Col>
        <Col lg={3} sm={12} md={12}>
          <SearchTranscript q={props.q} keyphrases={props.keyphrases} documented={documented} renderTranscript={renderTranscript} selectedTab={selectedTab} />
        </Col>
      </Row>
    
    </main>
    </ErrorBoundary>
  );
}

export default observer(Details);
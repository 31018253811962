import React from "react";
import { List } from "@material-ui/core";
import CheckboxFacet from "./CheckboxFacet/CheckboxFacet";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import "./Facets.scss";

export default function Facets(props) {
  function mapFacetName(facetName) {
    const capitalizeFirstLetter = (string) =>
      string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : "";
    facetName = capitalizeFirstLetter(facetName);
    facetName = facetName.replace(/_/g, " ");
    if (facetName === "Metadata storage file extension lower") {
      facetName = facetName.replace(
        /Metadata storage file extension lower/,
        "Document Type"
      );
    }
    if (facetName === "Metadata storage file extension") {
      facetName = facetName.replace(
        /Metadata storage file extension/,
        "Document Type"
      );
    }
    if (facetName === "Metadata creation date") {
      facetName = facetName.replace(/Metadata creation date/, "Time Period");
    }
    if (facetName === "Metadata project") {
      facetName = facetName.replace(/Metadata project/, "Project");
    }
    if (facetName === "Metadata discipline") {
      facetName = facetName.replace(/Metadata discipline/, "Discipline");
    }
    if (facetName === "Metadata businessunit") {
      facetName = facetName.replace(/Metadata businessunit/, "Business Unit");
    }
    if (facetName === "Metadata location") {
      facetName = facetName.replace(/Metadata location/, "Location");
    }
    return facetName;
  }

  function addFilter(name, value) {
    const newFilters = props.filters.concat({ field: name, value: value });
    props.setFilters(newFilters);
  }

  function removeFilter(filter) {
    const newFilters = props.filters.filter(
      (item) => item.value !== filter.value
    );
    props.setFilters(newFilters);
  }

  function resetFilters() {
    props.setFilters([]);
  }

  function applayFilters() {
    props.applayFilters();
  }

  var facets;
  try {
    facets = Object.keys(props.facets).map((key) => {
      return (
        <CheckboxFacet
          key={key}
          name={key}
          values={props.facets[key]}
          addFilter={addFilter}
          removeFilter={removeFilter}
          mapFacetName={mapFacetName}
          selectedFacets={props.filters.filter((f) => f.field === key)}
          isLoading={props.isLoading}
        />
      );
    });
  } catch (error) {
    // console.log(error);
  }

  return (
    <div id="facetPanel" className="box">
      <div className="facetbox">
        <div id="clearFilters" className="facet-settings">
          <div className="filters-wrapper" style={{ 'display': 'flex','align-items':'center' }}>
            <div className="filters">
              <div className="filters-title">FILTERS</div>
              <div>
              <Tooltip title={<label className='tootltip-Font'>apply filter</label>} arrow>
                <Button
                  style={{
                    border: "1px solid #ffff",
                    width: "5rem",
                    height: "1.5rem",
                    textTransform: "capitalize",
                    backgroundColor: '#0c629b',
                    marginRight: '0.8vw',
                    color: "#ffff",
                    font: "normal normal 0.93rem Helvetica"
                  }}
                  onClick={applayFilters}
                  // color="primary"
                >
                  APPLY
                </Button>
              </Tooltip>
              <Tooltip title={<label className='tootltip-Font'>Reset Filters</label>} arrow>
                <img
                  className="reset-icon"
                  src="/images/Reset.svg"
                  alt=""
                  onClick={resetFilters}
                />
              </Tooltip>
              </div>
              {/* <img className="settings-icon" src="/images/Settings.svg" style={{"marginLeft":"5%", "cursor":"pointer"}}/> */}
            </div>
          </div>
        </div>
        <FacetList component="nav" className="listitem" >
          {facets}
        </FacetList>
      </div>
    </div>
  );
}

const FacetList = styled(List)({
  marginTop: "0 !important",
  paddingTop: "0 !important",
});

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppHeader from "../../components/AppHeader/AppHeader";
import BootstrapTable from "react-bootstrap-table-next";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";
import DialogActions from "@material-ui/core/DialogActions";
import CircleChecked from "@material-ui/icons/CheckCircleRounded";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextFieldMui from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import UserStore from "../../UserStore";
import { toJS } from "mobx";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import axios from "axios";
import "./Regex.scss";

const styles = (muiTheme) => ({
  label: {
    "&$erroredLabel": {
      color: "black",
    },
  },
  erroredLabel: {},
});
const TextField = withStyles(styles)(function TextField({ classes, ...props }) {
  return (
    <TextFieldMui
      InputLabelProps={{
        classes: {
          root: classes.label,
          error: classes.erroredLabel,
        },
      }}
      {...props}
    />
  );
});
export default function Regex() {
  const [regexValue, setregexValue] = useState("");
  const [regexValueErrorMessage, setRegexValueErrorMessage] = useState("");
  const [regexValueError, setRegexValueError] = useState(false);
  const [products, setProducts] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [deleteLOC, setDeleteLOC] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [columns, setColumns] = useState([
    { dataField: "regularExpression", text: "Regular Expression" },
    { dataField: "more", text: "More" },
  ]);
  const [clickCount, setclickCount] = useState(0);
  const [editRowData, setEditRowData] = useState(false);
  const [editRowid, setEditRowid] = useState("");
  const { instance, accounts } = useMsal();
  let [accountsValue] = accounts;
  const history = useHistory();
  const API_endpoint = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    if (accountsValue) {
      GetKmAssetTagRules();
    }
  }, [accountsValue]);

  function navigateToHome() {
    history.go(-1);
  }

  async function GetKmAssetTagRules() {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue,
    });
    const accessToken = response.accessToken;

    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    let res = await axios.get(API_endpoint + `/GetKmAssetTagRules`, config);
    formatRegexData(res.data);
  }

  async function handleSave() {
    if (regexValue === "") {
      setRegexValueError(true);
      setRegexValueErrorMessage("Before save have to enter some text");
    } else {
      setRegexValueError(false);
      setRegexValueErrorMessage("");
    }
    if (regexValue !== "" && clickCount === 0) {
      setclickCount(1);
      // Silently acquires an access token which is then attached to a request for MS Graph data
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        scopes: [process.env.REACT_APP_API_SCOPE],
        account: accountsValue,
      });
      const accessToken = response.accessToken;

      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      let updatedValue;
      if (editRowData) {
        updatedValue = products;

        const result = await axios.post(
          API_endpoint + `/EditKmAssetTagRule`,
          {
            assetTagRuleId: updatedValue[editRowid].assetTagRuleId,
            regularExpression: regexValue,
          },
          config
        );

        if (result.status == 200) {
          GetKmAssetTagRules();
          updatedValue[editRowid].regularExpression = regexValue;
          formatRegexData(updatedValue);
          setEditRowid("");
          setclickCount(1);
          setEditRowData(false);
        }
        setShowAddPopup(false);
      } else {
        const result = await axios.post(
          API_endpoint + `/AddKmAssetTagRule`,
          { regularExpression: regexValue },
          config
        );

        if (result.status == 200) {
          GetKmAssetTagRules();
          setclickCount(1);
        }
      }
      setShowAddPopup(false);
    }
  }

  const handleOpen = (e) => {
    setregexValue("");
    setRegexValueError(false);
    setShowAddPopup(true);
    setclickCount(0);
  };

  async function reRun() {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue,
    });
    const accessToken = response.accessToken;

    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    await axios.post(API_endpoint + `/KmResetSkill`, {}, config);
  }

  const handleclose = (e) => {
    setShowAddPopup(false);
    setEditRowData(false);
  };

  const regularExpressionChange = (e) => {
    setregexValue(e.target.value);
    setRegexValueError(false);
  };

  const editRow = (e) => {
    const indexValue = Number(e.target.id);
    setEditRowid(indexValue);
    setregexValue(toJS(UserStore.regexData)[indexValue].regularExpression);
    setRegexValueError(false);
    setEditRowData(true);
    setShowAddPopup(true);
    setclickCount(0);
  };

  async function handleLOCPurge(e) {
    setDeleteLOC(false);
    if (clickCount === 0) {
      setclickCount(1);
      const allData = toJS(UserStore.regexData);
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        scopes: [process.env.REACT_APP_API_SCOPE],
        account: accountsValue,
      });
      const accessToken = response.accessToken;

      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      let result = await axios.post(
        API_endpoint + `/DeleteKmAssetTagRule`,
        { assetTagRuleId: allData[Number(deleteId)].assetTagRuleId },
        config
      );
      if (result.status === 200) {
        allData.splice(deleteId, 1);
        formatRegexData(allData);
        setDeleteLOC(false);
        setDeleteId("");
        setclickCount(0);
      }
    }
  }

  const formatRegexData = (processedData) => {
    const finalData = processedData.map((items, index) => ({
      assetTagRuleId: items.assetTagRuleId,
      regularExpression: items.regularExpression,
      more: (
        <div
          classname="more-actions-wrapper"
          style={{ display: "inline-flex" }}
        >
          <Tooltip title={<label className='tootltip-Font'>Edit Expression</label>} arrow>
            <div
              classname="edit-icon"
              style={{ marginLeft: "-40%", cursor: "pointer" }}
              onClick={editRow}
              id={index}
            >
              <img
                id={index}
                onClick={editRow}
                src="/images/icon_edit.svg"
                alt="icon_edit"
              />
            </div>
          </Tooltip>
          <Tooltip title={<label className='tootltip-Font'>Delete Expression</label>}  arrow>
            <div
              classname="delete-icon"
              style={{ marginLeft: "70%", cursor: "pointer" }}
              onClick={(e) => {
                setDeleteLOC(true);
                setclickCount(0);
                setDeleteId(e.target.id);
              }}
              id={index}
            >
              <img id={index} src="/images/icon_delete.svg" alt="icon_delete" />
            </div>
          </Tooltip>
        </div>
      ),
    }));
    setProducts(finalData);
    // setProducts(finalData);
    UserStore.setRegexData(finalData);
  };

  const handleCloseDialog = () => {
    setDeleteLOC(false);
    setDeleteId("");
  };

  return (
    <div className="regex-page-container">
      <AppHeader nextPage={'searchResultPage'}/>

      <div className="workspace-wrapper">
        <div className="worspace-titlebar">
          <div className="back">
            <button className="back-btn" onClick={navigateToHome}>
              Back
            </button>
          </div>
          <div className="workspace-title">Regular Expression</div>
          <div
            className="create-new-workspace-wrapper"
            style={{ display: "flex" }}
          >
            <Button
              variant="contained"
              align="right"
              className="next-btn"
              onClick={reRun}
              style={{ marginRight: "1vw" }}
            >
              Reset Skill
            </Button>

            <Button
              variant="contained"
              align="right"
              className="next-btn"
              onClick={handleOpen}
            >
              Add row
            </Button>
          </div>
        </div>
        <div className="workspace-data-table-wrapper">
          <BootstrapTable
            className="workspace-data-table"
            keyField="id"
            data={products}
            columns={columns}
          />
        </div>

        <Dialog
          open={showAddPopup}
          onClose={handleclose}
          aria-labelledby="form-dialog-title"
        >
          {editRowData ? (
            <DialogTitle id="form-dialog-title">Edit Expression</DialogTitle>
          ) : (
            <DialogTitle id="form-dialog-title">Add Expression</DialogTitle>
          )}
          <DialogContent
            style={{ width: "35vw", display: "flex", flexDirection: "column" }}
          >
            <TextareaAutosize
              margin="dense"
              id="Expression"
              name="Expression"
              placeholder="Expression"
              fullWidth
              minRows={10}
              style={{
                font: "normal normal Helvetica 1.04vw",
                height: "5vw",
                overflowY: "scroll",
              }}
              error={regexValueError}
              helperText={regexValueErrorMessage}
              onChange={regularExpressionChange}
              value={regexValue}
            />
            {editRowData ? (
              <div>*Edit regular expression will reset the skill.</div>
            ) : (
              <div>*Adding new regular expression will reset the skill.</div>
            )}
            {regexValueError ? (
              <div style={{ color: "red" }}>
                Please enter Regular Expression
              </div>
            ) : (
              <div></div>
            )}
          </DialogContent>
          <DialogActions style={{ paddingright: "2vw" }}>
            <Button
              style={{
                border: "1px solid #4B4A55",
                background: "#0C629B",
                color: "#fff",
                width: "8.4vw",
                height: "2.29vw",
                textTransform: "capitalize",
              }}
              onClick={handleSave}
              color="primary"
            >
              Save
            </Button>
            <Button
              style={{
                border: "1px solid #4B4A55",
                width: "8.4vw",
                height: "2.29vw",
                textTransform: "capitalize",
              }}
              onClick={handleclose}
              color="primary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          className="delete-confirm"
          open={deleteLOC}
          onClose={handleCloseDialog}
          aria-labelledby="regexp-form-dialog-title"
        >
          <DialogTitle
            id="regexp-form-dialog-title"
            style={{ height: "0.625em" }}
          ></DialogTitle>
          <DialogContent>
            <div style={{ fontSize: "1vw" }}>Are you sure to delete?</div>
            <IconButton
              onClick={handleCloseDialog}
              style={{
                padding: "0",
                float: "right",
                background: "#0C629B",
                color: "#fff",
                position: "absolute",
                right: "15%",
                width: "2vw",
                height: "2vw",
              }}
            >
              {" "}
              <Cancel />{" "}
            </IconButton>
            <IconButton
              onClick={(e) => handleLOCPurge(e)}
              style={{
                padding: "0",
                background: "#0C629B",
                color: "white",
                position: "absolute",
                right: "2%",
                width: "2vw",
                height: "2vw",
              }}
            >
              {" "}
              <CircleChecked />{" "}
            </IconButton>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

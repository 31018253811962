import React from 'react';
import Result from './Result/Result';
import "./Results.scss";

export default function Results(props) {
  
  let results = props.documents.map((result, index) => {
    return <Result 
        key={index} 
        document={result.document}
        q={props.q}
      />;
  });

  let beginDocNumber = Math.min(props.skip + 1, props.count);
  let endDocNumber = Math.min(props.skip + props.top, props.count);



  return (
    <div>
      <p className="results-info searchResult-txt">Showing {beginDocNumber}-{endDocNumber} of {props.count.toLocaleString()} results...</p>
      <div className="row row-cols-md-5 results">
        <div className='col-lg-12 col-sm-12 col-md-12 '>
          {results}
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getAppInsights } from '../TelemetryService';
import TelemetryProvider from '../telemetry-provider';

// React Router page components
import Home from '../pages/Home/Home';
import Search from '../pages/Search/Search';
import Details from '../pages/Details/Details';
import Regex from '../pages/Regex/Regex';

// Bootstrap styles, optionally with jQuery and Popper
import 'bootstrap/dist/css/bootstrap.min.css';

// Custom app styles
import './App.scss';

export default function App() {

  let appInsights = null;
  const instrumentationKey  = process.env.REACT_APP_INSTRUMENTATION_KEY;

  return (
      <div className="container-fluid app">
        <Router>
        {/* eslint-disable-next-line */}
          <TelemetryProvider instrumentationKey={instrumentationKey} after={() => { appInsights = getAppInsights() }}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/search" component={Search} />
              <Route path="/details/:id" component={Details} />
              <Route path="/regex" component={Regex} />
            </Switch>
          </TelemetryProvider>
        </Router>
      </div>
  );
}

import { action, makeObservable, observable } from "mobx"

class UserStore {
    loggedInUser = "";
    initialQ = "";
    regexData = [];
    isSelectedcheckbob = false;

  constructor() {
    makeObservable(this, {
      loggedInUser: observable,
      initialQ: observable,
      regexData: observable,
      isSelectedcheckbob: observable,
      // all the desired actions
      setLoggedInUser: action,
      setInitialQ: action,
      setRegexData: action,
      setisSelectedcheckbox: action,
    });
  }

  setLoggedInUser(user) {
    this.loggedInUser = user;
    return this.loggedInUser;
  }

  setInitialQ(q) {
    this.initialQ = q;
    return this.initialQ;
  }

  setRegexData(data) {
    this.regexData = data;
    return this.regexData;
  }

  setisSelectedcheckbox(data) {
    this.isSelectedcheckbob = data;
    return this.isSelectedcheckbob;
  }
}

const myStore = new UserStore();
export default myStore;
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../../graph";
import { loginRequest } from "../../authConfig";
import { profilePic } from "../../profilePic";
import UserStore from "../../UserStore";
import Tooltip from "@material-ui/core/Tooltip";
import { useIdleTimer } from "react-idle-timer";
import "./AppHeader.scss";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

//sougata's changes

import { ToastProvider } from "react-toast-notifications";
import SearchBar from "../../components/SearchBar/SearchBar";

export default function AppHeader(props) {
  const noWrapCss = {
    paddingTop: "-1%",
    flexWrap:'nowrap'
  }
  const wrapCss = {
    paddingTop: "-1%",
    flexWrap:'wrap'
  }
  const history = useHistory();
  const { instance, accounts } = useMsal();
  let [accountsValue] = accounts;
  let graphData,
    theImage,
    accessToken = "",
    config = "";
  const timeout = 1800000; /* 5 minutes in milli seconds - 300000, 1800000 for 30 minutes  */
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [isIdle, setIsIdle] = useState(false);
  const [show, setShow] = useState(false);
  const APIEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const handleLogout = async () => {
    // calling api for concurrent session management;
    const options = {
      UserId: localStorage.getItem("userEmail"),
      UniqueSessionCode: localStorage.getItem("uuid"),
      ApplicationName: "KM",
    };

   

    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue,
    });
    config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
    };

    const res = await axios.post(
      APIEndpoint + `/LogoutSessionTable`,
      options,
      config
    );
    localStorage.removeItem("uuid");
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
  } = useIdleTimer({
    timeout,
    onIdle: handleLogout,
  });

  useEffect(() => {
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
      setLastActive(getLastActiveTime());
      setElapsed(getElapsedTime());
    }, 1000);
  }, []);

  const navigateToHome = () => {
    history.push("/");
  };

  useEffect(() => {
    if (accountsValue) {
      localStorage.setItem("userEmail", accountsValue.username);
      apigraph();
      UserStore.setLoggedInUser(accountsValue.name);
    }
    // eslint-disable-next-line
  }, [accountsValue]);

  const validateSession = async (options) => {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue,
    });
    config = {
      headers: { Authorization: `Bearer ${response.accessToken}` },
    };
    const res = await axios.post(
      APIEndpoint + `/ValidateSessionTable`,
      options,
      config
    );

    if (res.data === false) {
      handleLogout();
    } else setTime(10000);
  };
  /* validate if user session is active or not every 20 secs */

  useEffect(() => {
    const interval = setInterval(() => {
      const options = {
        UserId: localStorage.getItem("userEmail"),
        UniqueSessionCode: localStorage.getItem("uuid"),
        ApplicationName: "KM",
      };

      validateSession(options);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const [time, setTime] = useState(10000);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (remaining <= 60000 && remaining > 1) {
      setShow(true);
    }
    if (remaining <= 1 || remaining > 60000) {
      setShow(false);
      // if(remaining <= 1) {
      //   handleLogout();
      // }
    }
  }, [remaining]);

  async function apigraph() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    const res = await instance.acquireTokenSilent({
      ...loginRequest,
      // scopes: [process.env.REACT_APP_API_SCOPE],
      account: accountsValue,
    });
    accessToken = res.accessToken;
    config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    if (localStorage.getItem("uuid") === null) {
      let myuuid = uuidv4();
      localStorage.setItem("uuid", myuuid);
      const res = await instance.acquireTokenSilent({
        ...loginRequest,
        scopes: [process.env.REACT_APP_API_SCOPE],
        account: accountsValue,
      });
      config = {
        headers: { Authorization: `Bearer ${res.accessToken}` },
      };

      const options = {
        UserId: localStorage.getItem("userEmail"),
        UniqueSessionCode: localStorage.getItem("uuid"),
        ApplicationName: "KM",
      };

      // calling api for concurrent session management;

      const result = axios.post(
        APIEndpoint + `/LoginSessionTable`,
        options,
        config
      );
    }

    theImage = await profilePic(res.accessToken);
    graphData = await callMsGraph(res.accessToken);

    const reader = new FileReader();
    const preview = document.querySelector(".navbar-logo");
    if (theImage.ok === true) {
      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          preview.src = reader.result;
        },
        false
      );

      if (graphData) {
        reader.readAsDataURL(graphData);
      }
    }
  }
  return (
    <>
      <header className="header">
        <nav className="navbar">
          <Tooltip
            title={<label className="tootltip-Font">Home Page</label>}
            arrow
          >
            <div className="petrofac-logo-wrapper" onClick={navigateToHome}>
              <img
                src="/images/Petrofac_Logo_RGB.svg"
                className="petrofac-logo-header"
                alt=""
              />
            </div>
          </Tooltip>

          <div className="navbar-list-item" id="navbarSupportedContent">
            <div className="navbar-nav" style={props.nextPage === "HomePage" ? noWrapCss:wrapCss}>
              <ul className="navbar-nav testCss" style={props.nextPage === "HomePage" ? noWrapCss:wrapCss}>
                  {props.nextPage === "HomePage" && (
                    <li>
                    <Tooltip
                      title={<label className="tootltip-Font">Help</label>}
                      arrow
                    >
                      <a
                        className="help"
                        style={{ borderRight: "1px solid" }}
                        href="/images/KnowledgeMining_User_Manual.pdf"
                        download="User Manual"
                      >
                        <svg
                          class="MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="HelpCenterOutlinedIcon"
                        >
                          <path d="M13.25 16.74c0 .69-.53 1.26-1.25 1.26-.7 0-1.26-.56-1.26-1.26 0-.71.56-1.25 1.26-1.25.71 0 1.25.55 1.25 1.25zM11.99 6c-1.77 0-2.98 1.15-3.43 2.49l1.64.69c.22-.67.74-1.48 1.8-1.48 1.62 0 1.94 1.52 1.37 2.33-.54.77-1.47 1.29-1.96 2.16-.39.69-.31 1.49-.31 1.98h1.82c0-.93.07-1.12.22-1.41.39-.72 1.11-1.06 1.87-2.17.68-1 .42-2.36-.02-3.08-.51-.84-1.52-1.51-3-1.51zM19 5H5v14h14V5m0-2c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h14z"></path>
                        </svg>
                        Help
                      </a>
                    </Tooltip>
                    </li>
                  )}
                
                  {props.isSearchResultPage && (
                    <li>
                    <div className="headerbar-searchbar">
                      <ToastProvider>
                        <SearchBar
                          postSearchHandler={props.postSearchHandler}
                          q={props.q}
                          setFilters={props.setFilters}
                        ></SearchBar>
                      </ToastProvider>
                    </div>
                    </li>
                  )}
              

                <li className="nav-item nav-user">
                  <img
                    className="navbar-logo"
                    alt="user"
                    src="images/profile_icon.svg"
                  />
                  {accountsValue ? (
                    <label className="userName">{accountsValue.name}</label>
                  ) : (
                    ""
                  )}
                </li>
                <li className="nav-item logout-wrapper">
                  <div className="nav-link nav-logout" onClick={handleLogout}>
                    <img
                      src="/images/logout.svg"
                      className="logout-icon"
                      alt="Logout"
                    />
                    <label className="logout-text">Logout</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <Modal
        className="timeout-session-popup"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="fullmodal"
        size="s"
        centered
      >
        <Modal.Body>
          <label
            style={{
              font: "normal normal bold 1vw Helvetica",
              //  textAlign: "left",
            }}
          >
            {"session will be expired in 60 seconds."}
            <IconButton
              onClick={handleClose}
              style={{
                padding: "3px",
                position: "absolute",
                top: "0.7vw",
                right: "0vw",
                background: "",
                borderRadius: "0",
                color: "#0C629B",
              }}
            >
              {" "}
              <CloseIcon style={{ width: "1.5vw", height: "1.5vw" }} />{" "}
            </IconButton>
          </label>
        </Modal.Body>
      </Modal>
    </>
  );
}

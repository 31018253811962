export default function getDocIcon(fileType) {
    var docType;
    fileType = fileType.toLowerCase();
    switch (fileType) {
        case ".pdf":
          docType = "/images/pdf.svg";
          break;
        case ".docx":
          docType = "/images/icon_docx.svg";
          break;
        case ".doc":
          docType = "/images/icon_docx.svg";
          break;
        case ".ppt":
          docType = "/images/icon_ppt.svg";
          break;
        case ".pptx":
          docType = "/images/icon_ppt.svg";
          break;
        case ".png":
          docType = "/images/icon_png.svg";
          break;
        case ".html":
          docType = "/images/icon_html.svg";
          break;
        case ".xls":
          docType = "/images/icon_xls.svg";
          break;
        case ".xlsx":
          docType = "/images/icon_xls.svg";
          break;
        case ".xlsm":
          docType = "/images/icon_xls.svg";
          break;
        case ".dwg":
          docType = "/images/icon_dwg.svg";
          break;
        case ".msg":
          docType = "/images/icon_msg.svg";
          break;
        case ".dgn":
          docType = "/images/icon_dgn.svg";
          break;
        case ".zip":
          docType = "/images/icon_zip.svg";
          break;
        case ".7z":
          docType = "/images/icon_7z.svg";
          break;
        case ".tif":
          docType = "/images/icon_tif.svg";
          break;
        case ".TIF":
          docType = "/images/icon_tif.svg";
          break;
        case ".jpg":
          docType = "/images/icon_jpg.svg";
          break;
        case ".txt":
          docType = "/images/txt.svg";
          break;
        case ".csv":
          docType = "/images/csv-1471.svg";
          break;
        case ".bmp":
          docType = "/images/bmp-file.svg";
          break;
        case ".jpeg":
          docType = "/images/jpeg-file.svg";
          break;
        case ".xml":
          docType = "/images/xml-file.svg";
          break;
        default:
          docType = "";
          break;
    }
    return docType;  
}